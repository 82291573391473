import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import matchdocs from '../../assets/images/pd/match-doctors.png'

import evaluation from '../../assets/images/mso/evaluation.jpg'
import homepage from '../../assets/images/mso/homepage.jpg'
import inputs from '../../assets/images/mso/input.jpg'
import requestappt from '../../assets/images/mso/request-appointment.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">Website</Text>
                                <Title mb="0.5em">
                                    Mountain Spine & Orthopedics
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    I've worked with the team of doctors at
                                    Mountain Spine & Orthopedics for several
                                    websites in the past. The 1st version of the
                                    website was built using a hubspot template.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Not happy with the original site, I rebuilt
                                    it using the more robust gatsby site
                                    framework. The marketing and patient team
                                    rely on many booking and intake forms that
                                    hubspot was just not capable of supporting
                                    the website requirements.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={matchdocs}
                                    alt="Website Analytics"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Mountain Spine & Orthopedics
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Late 2021
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.mountainspineandorthopedics.com"
                                    >
                                        website
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={matchdocs}
                                    alt="Match Docs"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Heathcare Marketing Website
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    A common request from clients was to create
                                    a high conversion website for marketing
                                    healthcare. I was only given a name and
                                    office to generate an online pressence for
                                    this healthcare company.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    It was originally designed using a hubspot
                                    template but failed to meet the clients
                                    needs. I was tasked to rebuild it so it was
                                    capable to produce 50 leads a day.
                                </Text>
                                <Text variant="p" mb="1.6em"></Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="gatsby" title="01. Gatsby Templates" left>
                        Using a new release of gatsby I created several simple
                        templates for building mobile first responsive sites.
                        Mountain Spine was the first to use these new templates.
                        My goal was to create a simple, mobile website that
                        would convert leads.
                    </Key>

                    <Key name="yarn" title="02. Yarn Workspaces">
                        I used package managers going back as far as composer
                        for php. I eventual migrated to NPM but this was the
                        first project I wanted to implement reuseable packages.
                        Yarn Workspaces were key to creating packages that would
                        later be used for all my recent work.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={homepage}
                                        alt="Mountain Spine Homepage"
                                    />
                                    <Screenshot
                                        src={requestappt}
                                        alt="Request Appointment"
                                    />
                                    <Screenshot
                                        src={evaluation}
                                        alt="Pain Evaluation"
                                    />
                                    <Screenshot
                                        src={inputs}
                                        alt="Pain Evaluation Inputs"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/atlantic-spine-center/">
                                Atlantic Spine Center
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
